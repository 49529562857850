// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseApiUrl: 'https://app.db.geoquebec.ca/api/',
  apiAuth: 'appvoyage',
  apiPass: 'y2c9q3mBa5',
  mapboxPK: 'pk.eyJ1IjoiZ2VvcXVlYmVjIiwiYSI6ImNrc3U2ZnRhMTA5dzczMXB1ZWY3aThnbGQifQ.RimuHPQh6QE_gRopYPKFZw',
  oneSignalID: '',
  oneSignalSafariID: '',
  googleProjectNumber: '',
  deeplinkSplit: '',
  baseUrl: 'https://geoquebec.ca/',
  baseShareUrl: 'https://geoquebec.ca/publication/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
